import * as React from "react";

import { PageProps } from "gatsby";

import webIcon from "src/images/services/fullWeb.svg";
import ServicePage from "src/routes/ServicesPages";
import Seo from "src/components/Seo";

const items = [
  [
    "Мобильная и веб разработка:",
    "лендинг, онлайн-магазин, онлайн-образование, спецпроекты, сайты для бизнеса, мобильные приложения.",
  ],
  "UX и UI дизайн",
  "Автоматизация бизнес-процессов",
  "Перенос бизнеса в онлайн",
  "Разработка сервисов и интеграции сервисов",
  "Создание компонентов и расширение функционала онлайн систем",
  "Индивидуальные it-решения",
];

const workStages = [
  "Сбор требований",
  "Подготовка коммерческого предложения",
  "Первоначальное проектирование",
  "Прототипирование и согласование интерфейса",
  "Разработка и согласование нескольких стилевых решений",
  "Разработка ПО",
  "Тестирование",
  "Запуск",
  "Поддержка",
];

const WebPage: React.SFC<PageProps> = () => {
  return (
    <div>
      <Seo
        pageTitle="Веб"
        pageDescription="Разрабатываем сайты и мобильные приложения для бизнеса. Делаем лендинги, онлайн-магазины, онлайн-образование, спецпроекты, мобильные приложения. Индивидуальные решения под ваш бюджет. Digital."
      />
      <ServicePage
        workStages={workStages}
        header="Веб"
        img={webIcon}
        listItems={items}
      />
    </div>
  );
};

export default WebPage;
